<template>
    <modal ref="modalAnadirCompra" titulo="Añadir orden de compra" no-aceptar adicional="Añadir" @adicional="postGuardarOrdenCompra">
        <cargando v-if="cargando" />
        <div class="row mx-0">
            <p class="col-12 text-center text-general f-12">
                Indique en que momento y a que CEDIS llegarán los productos
            </p>
        </div>
        <ValidationObserver ref="validacion">
            <div class="row mx-0 mt-4">
                <div class="col-12">
                    <ValidationProvider v-slot="{errors}" rules="required" name="Fecha">
                        <p class="label-mini">
                            Seleccionar fecha
                        </p>
                        <el-date-picker
                        v-model="fecha"
                        class="w-100"
                        size="small"
                        type="date"
                        placeholder="Seleccionar"
                        format="d [de] MMMM [del] yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 mt-4">
                <div class="col">
                    <p class="label-mini">Hora Inicio</p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Hora Inicial">
                        <el-time-select
                        v-model="hora_inicial"
                        class="w-100"
                        size="small"
                        placeholder="Hora inicio"
                        format="hh:mm a"
                        value-format="HH:mm"
                        :picker-options="{
                            start: '00:00',
                            step: '00:30',
                            end: '23:00',
                            format: 'hh:mm a',
                            valueFormat:'HH:mm',
                            maxTime: hora_final
                        }"
                        />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col">
                    <p class="label-mini">Hora Fin</p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Hora Fin">
                        <el-time-select
                        v-model="hora_final"
                        class="w-100"
                        size="small"
                        placeholder="Hora fin"
                        format="hh:mm a"
                        value-format="HH:mm"
                        :picker-options="{
                            start: '00:00',
                            step: '00:30',
                            end: '23:00',
                            format: 'hh:mm a',
                            valueFormat:'HH:mm',
                            minTime: hora_inicial
                        }"
                        />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 mt-4">
                <div class="col-12 pr-0">
                    <p class="label-mini">
                        Proveedor
                    </p>
                    <el-select v-model="id_proveedor" size="small" class="w-100" :clearable="true" @clear="limpiarProveedor">
                        <el-option
                        v-for="proveedor in proveedores"
                        :key="proveedor.id"
                        :label="proveedor.nombre"
                        :value="proveedor.id"
                        />
                    </el-select>
                </div>
                <div class="col-12 pr-0 mt-4">
                    <p class="label-mini"> Comentario </p>
                    <ValidationProvider v-slot="{errors}" rules="max:500" name="Comentario">
                        <el-input v-model="comentario" :rows="4" type="textarea" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 pl-3 mt-3">
                <div class="col-12 d-flex">
                    <el-upload
                    ref="upload"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :on-change="changeFile"
                    :on-remove="removeFile"
                    :auto-upload="false"
                    :multiple="false"
                    :limit="1"
                    accept=".xlsx"
                    >
                        <div class="bg-general2 text-white br-8 px-3 py-1 f-16 cr-pointer shadow">
                            Click para subir archivo
                        </div>
                    </el-upload>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Ordenes from "~/services/cedis/cedis_ordenes_compras"
import moment from 'moment'

export default {
    data(){
        return {
            cargando: false,
            cedis: [],
            proveedores: [],
            hora: '',
            fecha: '',
            id_proveedor: '',
            comentario: '',
            file: '',
            fileList: [],
            fecha_min: '',
            hora_inicial: '',
            hora_final: '',
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            }
        }
    },
    computed:{
        idCedis(){
            return Number(this.$route.params.id_cedis)
        }
    },
    methods: {
        toggle(fecha = null){
            if(fecha){
                this.fecha = moment(fecha.start).format('YYYY-MM-DD');
                this.hora_inicial = moment(fecha.start).format('HH:mm');;
                this.hora_final =  moment(fecha.end).format('HH:mm');;
            }else{
                this.hora_inicial = '';
                this.hora_final = '';
                this.fecha = '';
            }
            this.getInfo();
            this.limpiar();
            this.$refs.modalAnadirCompra.toggle();
        },
        async getInfo(){
            try {
                const {data} = await Ordenes.getInfoModalGuardarOC(this.idCedis);
                this.proveedores = data.proveedores;
            } catch (e){
                this.error_catch(e)
            }
        },
        async postGuardarOrdenCompra(){
            try {

                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    return this.notificacion('Mensaje', 'Los campos marcados son obligatorios', 'warning')
                }

                this.cargando = true;
                let form = {
                    hora_inicio: this.hora_inicial,
                    hora_fin: this.hora_final,
                    fecha: this.fecha,
                    id_cedi: this.idCedis,
                    id_proveedor: this.id_proveedor,
                    comentario: this.comentario,
                    file: this.file,
                }
                form = this.crear_formData(form)
                const {data} = await Ordenes.postGuardarOrdenCompra(form);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$refs.modalAnadirCompra.toggle();
                this.$router.push({name: 'admin.cedis.ordenes-compra.detalle', params: { id_oc: data.id_oc }});
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
        changeFile(file){
            this.file = file.raw
        },
        removeFile(){
            this.file = ''
        },
        limpiar(){
            this.$refs.validacion.reset();
            this.id_proveedor = '';
            this.comentario = '';
            this.file = '';
            this.$refs.upload.clearFiles();
        },
        limpiarProveedor(){
            this.id_proveedor = "";
        },
    }
}
</script>
<style lang="css" scoped>
.label-mini{
    font-size: 12px !important;
    color: #000000;
    padding-left: 15px;
}
.btn-alternative{
    background-color: #707070;
    border-radius: 8px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
}
</style>
